import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from 'providers/ProjectProvider';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';

import styles from './styles.module.less';
import { Button, Tooltip } from 'components/basic';

const PreviewButton = () => {
  const { previewUrl } = useProjectContext();
  const { t } = useTranslation('common');

  return (
    <Tooltip title={t('preview')}>
      <Button
        className={styles.btn}
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Button,
          prefix: 'preview'
        })}
        onClick={() => window.open(previewUrl, '_blank')}
        icon={
          <EyeOutlined
            style={{
              fontSize: 20
            }}
          />
        }
      ></Button>
    </Tooltip>
  );
};

export default PreviewButton;
