import { Flex } from 'antd';
import { FC } from 'react';

import { Typography } from 'components/basic';

const { UcBodyText } = Typography;

interface Props {
  title: string;
  description: string;
}

const DropdownItem: FC<Props> = ({ title, description }) => {
  return (
    <Flex vertical>
      <UcBodyText className="title" fontWeight="medium" size="small">
        {title}
      </UcBodyText>
      <UcBodyText type="secondary">{description}</UcBodyText>
    </Flex>
  );
};

export default DropdownItem;
