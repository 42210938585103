import { Dispatch, SetStateAction } from 'react';
import { Maybe as GQLMaybe } from 'graphql/jsutils/Maybe';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import { Dayjs } from 'dayjs';

export type Nullable<T> = { [K in keyof T]: T[K] | undefined };

export interface KeyValuePair<T = any> {
  [key: string]: T;
}

export type CoreConfig = {
  code: string;
  value: string;
};

export type ID = string | number;

export type PureNullable<T> = T | null;

/**
 * Type of function that takes no arguments and has no return statement
 */
export type NiladicVoid = () => void;

export type StateDispatcher<S> = Dispatch<SetStateAction<S>>;

/**
 * Generic type that is either of type <T> or undefined;
 */
export type Undefinable<T> = T | undefined;

export enum SortTypes {
  Az = 'Az',
  Za = 'Za',
  Newest = 'Newest',
  Oldest = 'Oldest',
  PriceAsc = 'priceAsc',
  PriceDesc = 'PriceDesc',
  RecentlyModified = 'RecentlyModified',
  HighestRating = 'HighestRating',
  LowestRating = 'LowestRating'
}

export type FilterOption<T = SortTypes> = {
  value: T;
  name: string;
};

export type Maybe<T> = GQLMaybe<T>;

export type RemoteValidationErrorBody = {
  validateStatus: ValidateStatus;
  help: string;
};

export interface RemoteValidationError
  extends KeyValuePair<RemoteValidationErrorBody> {}

export enum KeyboardShortcutKeys {
  O = 'KeyO',
  P = 'KeyP',
  A = 'KeyA',
  C = 'KeyC',
  D = 'KeyD',
  S = 'KeyS',
  L = 'KeyL',
  N = 'KeyN',
  F = 'KeyF',
  Meta = 'Meta',
  Control = 'Control',
  Enter = 'Enter'
}

export enum OperatingSystems {
  MAC = 'MAC',
  LINUX = 'LINUX',
  WINDOWS = 'WINDOWS',
  OTHER = 'OTHER'
}

declare global {
  interface Window {
    hoory: KeyValuePair;
    $hoory: KeyValuePair;
    $hoory2: KeyValuePair;
    hoorySDK?: KeyValuePair;
    hoorySettings: KeyValuePair;
    SeatmapAdminRenderer: any;
    onUsersnapCXLoad: (api: IUsersnapApiProps) => void;
    usersnapApi: IUsersnapApiProps;
    median?: KeyValuePair;
    MSStream?: KeyValuePair;
    promptAppRating?: KeyValuePair;
    webkit?: KeyValuePair;
  }
}

export type RangePickerValue = RangePickerProps<Dayjs>['value'];

export interface Permission {
  I: string;
  Or?: string;
  RequestAccess?: string;
}

export enum DashboardSidebarRouteKey {
  Dashboard = 'DASHBOARD',
  Orders = 'ORDERS',
  AbandonedCheckouts = 'ABANDONED_CHECKOUTS',
  Products = 'PRODUCTS',
  ProductCategories = 'PRODUCT_CATEGORIES',
  Attributes = 'ATTRIBUTES',
  ProductFamilies = 'PRODUCT_FAMILIES',
  Events = 'EVENTS',
  EventsCategories = 'EVENTS_CATEGORIES',
  Attendees = 'ATTENDEES',
  Collections = 'COLLECTIONS',
  CollectionCategories = 'COLLECTION_CATEGORIES',
  CollectionItems = 'COLLECTION_ITEMS',
  FormFields = 'FORM_FIELDS',
  Customers = 'CUSTOMERS',
  CustomerGroups = 'CUSTOMER_GROUPS',
  CustomerReviews = 'CUSTOMER_REVIEWS',
  FormSubmissions = 'FORM_SUBMISSIONS',
  Discounts = 'DISCOUNTS',
  AnalyticsOverview = 'ANALYTICS_OVERVIEW',
  Apps = 'APPS',
  ActivityLog = 'ACTIVITY_LOG',
  Settings = 'SETTINGS',
  SalesChannels = 'SALES_CHANNELS',
  EventTypes = 'EVENT_TYPES',
  Bookings = 'BOOKINGS',
  BookingCategories = 'BOOKING_CATEGORIES',
  BookingTypes = 'BOOKING_TYPES',
  Reservation = 'RESERVATIONS',
  RentalOrders = 'RENTAL_ORDERS',
  Rentals = 'RENTALS',
  RentalCategories = 'RENTAL_CATEGORIES',
  EventsAbandonedCheckouts = 'EVENTS_ABANDONED_CHECKOUTS',
  BookingsAbandonedCheckouts = 'BOOKINGS_ABANDONED_CHECKOUTS',
  RentalsAbandonedCheckouts = 'RENTALS_ABANDONED_CHECKOUTS',
  RentalTypes = 'RENTAL_TYPES',
  Shipments = 'SHIPMENTS'
}

export enum ERROR_CODES {
  SERVICE_UNAVAILABLE = 418
}

export enum SmartEntityType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export interface IUsersnapApiProps {
  init: (params: KeyValuePair<string>) => void;
  destroy: () => Promise<void>;
  logEvent: (eventName: string) => Promise<void>;
  on: (eventName: string, callback: NiladicVoid) => void;
  off: (eventName: string, callback: NiladicVoid) => void;
  show: (projectId: string) => Promise<{ open: NiladicVoid }>;
  hide: (projectId: string) => Promise<void>;
}

export enum MessageTypes {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export const customHeaders = {
  'X-Requested-With': 'XMLHttpRequest'
};

export type BookingDataKey =
  | 'book_from'
  | 'book_till'
  | 'rent_from'
  | 'rent_till'
  | 'rent_type';

export interface ICountry {
  code: string;
  continentCode: string;
  continentId: number;
  id: number;
  name: string;
  emojiU: string;
  phonecode: string;
}

export interface IPhone {
  emoji: string;
  countryCode: string;
  phoneCode: string;
  countryName: string;
}

export type ArgumentTypes<F extends Function> = F extends (
  ...args: infer A
) => any
  ? A
  : never;

export type TypeWithDataTest<T> = T extends object
  ? T & { 'data-test'?: string }
  : T;

export enum ModalTypes {
  CHANGE_PLAN = 'change_plan',
  PAYMENT_METHODS = 'payment_methods'
}

export enum NavigationKeys {
  CMS = 'CMS',
  CONTENT = 'CONTENT',
  VISUAL_EDITOR = 'VISUAL_EDITOR'
}
