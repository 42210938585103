import { FC, MouseEventHandler } from 'react';

import { HeadsetIcon } from 'components/shared/SVG';
import { HeaderButton } from 'components/shared';

interface ContactSupportButtonProps {
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
}

const ContactSupportButton: FC<ContactSupportButtonProps> = ({
  onClick,
  disabled
}) => {
  return (
    <HeaderButton
      disabled={disabled}
      onClick={onClick}
      icon={<HeadsetIcon />}
      dataTestPrefix="chat"
    />
  );
};

export default ContactSupportButton;
