import { App, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from 'providers/ProjectProvider';
import { createDataTestAttribute } from 'helpers/automationHelpers';
import { DataTestAttributes } from 'helpers/automationHelpers/types';
import useCanFunction from 'providers/PermissionAndLimitationProvider/hooks/useCanFunction';
import { usePermissionAndLimitationContext } from 'providers/PermissionAndLimitationProvider';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useCallback, useMemo } from 'react';
import {
  Channel,
  useChannelLazyQuery,
  useChannelsQuery,
  useVisualEditorVisitedMutation
} from 'generatedHooks/commerce/generated';
import goToVisualEditorConfirmation from 'helpers/commonHelpers/goToVisualEditorConfirmation';
import { navigateTo } from 'helpers';
import { NavigationKeys } from 'core/globalTypes';

import {
  AdvancedModeIcon,
  ArrowDownIcon,
  ContentModeIcon,
  DashboardIcon,
  DesignModeIcon
} from 'components/shared/SVG';
import { Button, Dropdown } from 'components/basic';
import DropdownItem from './components/DropdownItem';
import styles from './styles.module.less';
import LockIcon from './components/LockIcon';
import IconContainer from '../IconContainer';

const { useApp } = App;

const DesignDropdown = () => {
  const { modal } = useApp();
  const { id } = useProjectContext();
  const { t } = useTranslation(['channels', 'common']);
  const CanFunction = useCanFunction('menu');

  const { data } = useChannelsQuery();
  const channel = useMemo(() => data?.channels?.data[0], [data]);
  const [getChannel] = useChannelLazyQuery();

  const [markVisualEditorVisited] = useVisualEditorVisitedMutation({
    variables: {
      id: channel?.id as string
    },
    update(cache) {
      cache.modify({
        fields: {
          channels: (existing = { data: [] }, { readField }) => {
            return existing.data.map((exChannel: Channel) => {
              if (readField('id', exChannel) === channel?.id) {
                return {
                  ...exChannel,
                  visualEditorVisited: true
                };
              }

              return exChannel;
            });
          }
        }
      });
    }
  });

  const {
    state: {
      permissions: {
        actions: { Access }
      }
    }
  } = usePermissionAndLimitationContext();

  const openVisualEditor = () => navigateTo(NavigationKeys.VISUAL_EDITOR, id);

  const confirmVisualEditorNavigation = useCallback(
    () =>
      goToVisualEditorConfirmation({
        t,
        confirm: modal.confirm,
        onOk() {
          try {
            markVisualEditorVisited();
          } catch (e) {
            console.error(e);
          } finally {
            openVisualEditor();
          }
        }
      }),
    []
  );

  const goToVisualEditor = useCallback(async () => {
    // If channel.visualEditorVisited was initially true, no need to revalidate this value
    if (channel?.visualEditorVisited) {
      openVisualEditor();

      return;
    }

    try {
      // Revalidating channel data, to make sure the of visualEditorVisited is fresh
      const { data } = await getChannel({
        fetchPolicy: 'network-only'
      });

      data?.channel?.visualEditorVisited
        ? openVisualEditor()
        : confirmVisualEditorNavigation();
    } catch (e) {
      confirmVisualEditorNavigation();
    }
  }, [channel?.visualEditorVisited]);

  const items = [
    {
      label: (
        <DropdownItem
          title={t('common:dashboard')}
          description={t('common:dashboardDescription')}
        />
      ),
      icon: (
        <IconContainer>
          <DashboardIcon />
        </IconContainer>
      ),
      key: 'dashboard',
      className: styles.menu_item
    },
    CanFunction({
      I: Access.ContentMode,
      lockIcon: <LockIcon />,
      children: {
        key: 'content',
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: 'contentMode'
        }),
        onClick: () => navigateTo(NavigationKeys.CONTENT, id),
        label: (
          <DropdownItem
            title={t('common:contentMode')}
            description={t('common:contentModeDescription')}
          />
        ),
        icon: (
          <IconContainer>
            <ContentModeIcon />
          </IconContainer>
        ),
        className: styles.menu_item
      }
    }),
    CanFunction({
      I: Access.CMS,
      lockIcon: <LockIcon />,
      children: {
        key: 'design',
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: 'designMode'
        }),
        onClick: () => navigateTo(NavigationKeys.CMS, id),
        label: (
          <DropdownItem
            title={t('common:designMode')}
            description={t('common:designModeDescription')}
          />
        ),
        icon: (
          <IconContainer>
            <DesignModeIcon />
          </IconContainer>
        ),
        className: styles.menu_item
      }
    }),
    CanFunction({
      I: Access.VisualEditor,
      lockIcon: <LockIcon />,
      children: {
        key: 'advanced',
        'data-test': createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Option,
          prefix: 'advancedMode'
        }),
        onClick: goToVisualEditor,
        label: (
          <DropdownItem
            title={t('common:advancedMode')}
            description={t('common:advancedModeDescription')}
          />
        ),
        icon: (
          <IconContainer>
            <AdvancedModeIcon />
          </IconContainer>
        ),
        className: styles.menu_item
      }
    })
  ] as ItemType[];

  return (
    <Dropdown trigger={['click']} menu={{ items }}>
      <Button type="primary">
        <Flex align="center" className={styles.dropdown_button_content}>
          <div className={styles.dropdown_button_content_text}>
            {t('common:dashboard')}
          </div>
          <ArrowDownIcon />
        </Flex>
      </Button>
    </Dropdown>
  );
};

export default DesignDropdown;
