import { Flex } from 'antd';
import { FC, ReactNode } from 'react';

import styles from './styles.module.less';

interface Props {
  children: ReactNode;
}

const IconContainer: FC<Props> = ({ children }) => {
  return (
    <Flex
      className={`${styles.icon_container} icon_container`}
      align="center"
      justify="center"
    >
      {children}
    </Flex>
  );
};

export default IconContainer;
