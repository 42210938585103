import { useContext } from 'react';
import { HooryContext } from 'providers/HooryProvider';

import AiSupportIcon from 'components/shared/AiSupportIcon';

const AiSupport = () => {
  const {
    hoory: { openChat }
  } = useContext(HooryContext);

  return <AiSupportIcon onClick={openChat} style={{ cursor: 'pointer' }} />;
};

export default AiSupport;
