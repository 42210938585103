import { LockOutlined } from '@ant-design/icons';

import IconContainer from 'components/global/Header/components/IconContainer';

const LockIcon = () => {
  return (
    <IconContainer>
      <LockOutlined />
    </IconContainer>
  );
};

export default LockIcon;
