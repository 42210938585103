import { Actions } from 'providers/PermissionAndLimitationProvider';
import { DashboardSidebarRouteKey } from 'core/globalTypes';
import { RouteAliases } from 'routes/types';
import { DASHBOARD_SIDEBAR_ROUTES } from 'core/constants/common';

import { PagePermissionMapping } from './types';

const { Create, See, Update, Invite, RequestAccess, Manage } = Actions;

const baseRoutePermissionSelector = (key: DashboardSidebarRouteKey) =>
  DASHBOARD_SIDEBAR_ROUTES[key].permission;

//@ts-ignore
export const PAGE_PERMISSIONS: PagePermissionMapping = {
  [RouteAliases.DASHBOARD_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Dashboard
  ),
  [RouteAliases.ADD_PHYSICAL_PRODUCT_PAGE]: {
    I: Create.PhysicalProduct,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.ADD_DIGITAL_PRODUCT_PAGE]: {
    I: Create.DigitalProduct,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.ADD_APPOINTMENT_PRODUCT_PAGE]: {
    I: Create.AppointmentProduct,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },

  [RouteAliases.ADD_TABLE_PRODUCT_PAGE]: {
    I: Create.TableProduct,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.RENTALS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Rentals
  ),
  [RouteAliases.ADD_RENTAL_PRODUCT_PAGE]: {
    I: Create.RentalProduct,
    RequestAccess: RequestAccess.Rentals,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Rentals
    )
  },
  [RouteAliases.RENTAL_CATEGORIES]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.RentalCategories
  ),
  [RouteAliases.CREATE_RENTAL_AUTOMATIC_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.RentalCategories
    )
  },
  [RouteAliases.CREATE_RENTAL_MANUAL_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.RentalCategories
    )
  },
  [RouteAliases.EDIT_RENTAL_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.RentalCategories
    )
  },
  [RouteAliases.RENTAL_ORDERS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.RentalOrders
  ),
  [RouteAliases.RENTAL_ORDERS_DETAILS_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.RentalOrders
    )
  },
  [RouteAliases.ADD_GIFT_CARD_PRODUCT_PAGE]: {
    I: Create.GiftCardProduct,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.RENTALS_ABANDONED_CHECKOUT_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.RentalsAbandonedCheckouts
  ),
  [RouteAliases.RENTAL_TYPES_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.RentalTypes
  ),
  [RouteAliases.ADD_RENTAL_TYPE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.RentalTypes
    )
  },
  [RouteAliases.EDIT_RENTAL_TYPE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.RentalTypes
    )
  },
  [RouteAliases.EDIT_PHYSICAL_PRODUCT_PAGE]: {
    I: Update.PhysicalProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.EDIT_DIGITAL_PRODUCT_PAGE]: {
    I: Update.DigitalProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.EDIT_APPOINTMENT_PRODUCT_PAGE]: {
    I: Update.AppointmentProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.EDIT_TABLE_PRODUCT_PAGE]: {
    I: Update.TableProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.EDIT_RENTAL_PRODUCT_PAGE]: {
    I: Update.RentalProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Rentals,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Rentals
    )
  },
  [RouteAliases.EDIT_GIFT_CARD_PRODUCT_PAGE]: {
    I: Update.GiftCardProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Products,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Products
    )
  },
  [RouteAliases.ORDERS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Orders
  ),
  [RouteAliases.ORDER_DETAILS_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Orders
    )
  },
  [RouteAliases.ABANDONED_CHECKOUT_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.AbandonedCheckouts
  ),
  [RouteAliases.PRODUCTS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Products
  ),
  [RouteAliases.CATEGORIES_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.ProductCategories
  ),
  [RouteAliases.ADD_PRODUCT_AUTOMATIC_CATEGORIES_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.ProductCategories
    )
  },
  [RouteAliases.ADD_PRODUCT_MANUAL_CATEGORIES_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.ProductCategories
    )
  },
  [RouteAliases.EDIT_PRODUCT_CATEGORIES_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.ProductCategories
    )
  },
  [RouteAliases.ATTRIBUTES_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Attributes
  ),
  [RouteAliases.PRODUCT_FAMILY_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.ProductFamilies
  ),
  [RouteAliases.PRODUCT_FAMILY_CREATION_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.ProductFamilies
    )
  },
  [RouteAliases.PRODUCT_FAMILY_EDIT_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.ProductFamilies
    )
  },
  [RouteAliases.EVENTS]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Events
  ),
  [RouteAliases.ADD_EVENT]: {
    I: Create.EventProduct,
    RequestAccess: RequestAccess.Events,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Events
    )
  },
  [RouteAliases.EDIT_EVENT]: {
    I: Update.EventProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Events,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Events
    )
  },
  [RouteAliases.ATTENDEES_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Attendees
  ),
  [RouteAliases.SINGLE_ATENDEES_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Attendees
    )
  },
  [RouteAliases.EVENT_CATEGORIES]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.EventsCategories
  ),
  [RouteAliases.CREATE_EVENT_AUTOMATIC_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Events
    )
  },
  [RouteAliases.CREATE_EVENT_MANUAL_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Events
    )
  },
  [RouteAliases.EDIT_EVENT_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Events
    )
  },
  [RouteAliases.EVENT_TYPES_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.EventTypes
  ),
  [RouteAliases.EVENT_TYPE_CREATION_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.EventTypes
    )
  },
  [RouteAliases.EVENT_TYPE_EDIT_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.EventTypes
    )
  },
  [RouteAliases.EVENTS_ABANDONED_CHECKOUT_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.EventsAbandonedCheckouts
  ),
  [RouteAliases.BOOKINGS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Bookings
  ),
  [RouteAliases.CREATE_BOOKING_PAGE]: {
    I: Create.BookingProduct,
    RequestAccess: RequestAccess.Bookings,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Bookings
    )
  },
  [RouteAliases.EDIT_BOOKING_PAGE]: {
    I: Update.BookingProduct,
    Or: Manage.OwnProducts,
    RequestAccess: RequestAccess.Bookings,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Bookings
    )
  },
  [RouteAliases.RESERVATIONS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Reservation
  ),
  [RouteAliases.RESERVATION_DETAILS_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Reservation
    )
  },
  [RouteAliases.BOOKING_CATEGORIES]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.BookingCategories
  ),
  [RouteAliases.BOOKINGS_ABANDONED_CHECKOUT_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.BookingsAbandonedCheckouts
  ),
  [RouteAliases.CREATE_BOOKING_AUTOMATIC_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.BookingCategories
    )
  },
  [RouteAliases.CREATE_BOOKING_MANUAL_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.BookingCategories
    )
  },
  [RouteAliases.EDIT_BOOKING_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.BookingCategories
    )
  },
  [RouteAliases.BOOKING_TYPES_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.BookingTypes
  ),
  [RouteAliases.BOOKING_TYPE_CREATION_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.BookingTypes
    )
  },
  [RouteAliases.BOOKING_TYPE_EDIT_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.BookingTypes
    )
  },
  [RouteAliases.COLLECTIONS]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Collections
  ),
  [RouteAliases.ADD_COLLECTION]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Collections
    )
  },
  [RouteAliases.EDIT_COLLECTION]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Collections
    )
  },
  [RouteAliases.COLLECTION_ITEMS]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.CollectionItems
  ),
  [RouteAliases.ADD_COLLECTION_ITEM]: {
    I: Create.CollectionItem,
    RequestAccess: RequestAccess.CollectionItems,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.CollectionItems
    )
  },
  [RouteAliases.EDIT_COLLECTION_ITEM]: {
    I: Update.CollectionItem,
    Or: Manage.OwnCollectionItems,
    RequestAccess: RequestAccess.CollectionItems,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.CollectionItems
    )
  },
  [RouteAliases.COLLECTION_CATEGORIES]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.CollectionCategories
  ),
  [RouteAliases.CREATE_COLLECTION_AUTOMATIC_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.CollectionCategories
    )
  },
  [RouteAliases.CREATE_COLLECTION_MANUAL_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.CollectionCategories
    )
  },
  [RouteAliases.EDIT_COLLECTION_CATEGORY]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.CollectionCategories
    )
  },
  [RouteAliases.FORM_FIELDS]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.FormFields
  ),
  [RouteAliases.CUSTOMER_OVERVIEW_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Customers
  ),
  [RouteAliases.CUSTOMER_PREVIEW_PAGE]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Customers
    )
  },
  [RouteAliases.CUSTOMER_GROUPS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.CustomerGroups
  ),
  [RouteAliases.CUSTOMER_REVIEWS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.CustomerReviews
  ),
  [RouteAliases.FORM_SUBMISSIONS]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.FormSubmissions
  ),
  [RouteAliases.FORM_SUBMISSION]: {
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.FormSubmissions
    )
  },
  [RouteAliases.ANALYTICS_OVERVIEW_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.AnalyticsOverview
  ),
  [RouteAliases.DISCOUNT_LIST_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Discounts
  ),
  [RouteAliases.CREATE_AUTOMATIC_DISCOUNT]: {
    I: Create.AutomaticDiscount,
    RequestAccess: RequestAccess.Discounts,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Discounts
    )
  },
  [RouteAliases.CREATE_MANUAL_DISCOUNT]: {
    I: Create.ManualDiscount,
    RequestAccess: RequestAccess.Discounts,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Discounts
    )
  },
  [RouteAliases.UPDATE_AUTOMATIC_DISCOUNT]: {
    I: Update.AutomaticDiscount,
    RequestAccess: RequestAccess.Discounts,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Discounts
    )
  },
  [RouteAliases.UPDATE_MANUAL_DISCOUNT]: {
    I: Update.ManualDiscount,
    RequestAccess: RequestAccess.Discounts,
    baseRoutePermissions: baseRoutePermissionSelector(
      DashboardSidebarRouteKey.Discounts
    )
  },
  [RouteAliases.APPS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Apps
  ),
  [RouteAliases.ACTIVITY_LOG_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.ActivityLog
  ),
  [RouteAliases.SETTINGS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Settings
  ),
  [RouteAliases.SALES_CHANNELS_PAGE]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.SalesChannels
  ),
  [RouteAliases.CHECKOUT_PAGE]: {
    I: See.CustomCheckout
  },
  [RouteAliases.INVITE_MEMBER_PAGE]: {
    I: Invite.Member
  },
  [RouteAliases.SHIPMENTS]: baseRoutePermissionSelector(
    DashboardSidebarRouteKey.Shipments
  )
};
